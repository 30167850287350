.About {
    height: fit-content;

    .card {
        width: 80%;
        max-width: 1200px;
        margin: 30px auto;
        border-radius: 12px;
        border: 1px solid lightgrey;
        background-color: var(--container-bg-color);

        &:first-child {
            margin-top: 75px;
        }
        &:last-child {
            margin-bottom: 75px;
        }

        .container {
            margin: 2% 4%;

            .title {
                text-align: center;
                position: relative;
                
                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 1px;
                    width: 50%;
                    margin: 0 auto;
                    border-radius: 10px;
                    background-color: var(--main-bg-color);;
                }
            }
            .content {
                margin-top: 2%;
            }
        }
    }

    .Profile {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .cover {
            object-fit: cover;
            object-position: 0% 40%;
            width: 100%;
        }

        .content {
            display: grid;
            grid-template-columns: 50% 50%;
            margin: auto 10%;

            .first {
                margin-bottom: 10%;

                .headshot {
                    width: 200px;
                    margin-top: -30%;
                    border-radius: 50%;
                    border: 5px solid white;
                    box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.2);
                }

                h1 {
                    font-size: 35px;
                    padding-top: 3%;
                    padding-bottom: 2%;
                    margin: 0;
                }

                h2 {
                    font-size: 25px;
                    font-weight: 400;
                    padding-bottom: 2%;
                    margin: 0;
                }

                h3 {
                    font-size: 18px;
                    font-weight: normal;
                    color: rgb(104, 104, 104);
                    margin: 0;
                }

                .company {
                    font-size: 20px;
                    font-weight: 400;
                    width: fit-content;
                    margin-bottom: 3%;
                    padding: 1% 1.8%;
                    color: var(--invert-color);
                    background-color: var(--main-bg-color);
                    border-radius: 8px;

                    .parentcompany {
                        font-size: 16px;
                    }
                }
            }

            .second {
                margin: auto 0;
                letter-spacing: 2px;
                font-size: 20px;
                text-align: center;

                p {
                    margin: 0% 0% 5% 0%;
                }

                .social-container {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;

                    a {
                        height: fit-content;
                        display: flex;
                        align-items: center;
                    }

                    .social {
                        width: 44px;
                        transform: scale(1);
                        transition-duration: 0.3s;

                        &:hover {
                            transform: scale(1.2);
                            transition-duration: 0.3s;
                        }
                    }
                }
            }
        }
    }

    .Education {
        .container {
            display: flex;
            flex-direction: column;
            height: 100%;

            .title {
                font-size: 30px;
                font-weight: 400;
            }

            .content {
                .school {
                    margin: 0 6%;
                }
    
                .schoolname {
                    font-size: 30px;
                    font-weight: bold;
                }
    
                .degree {
                    font-size: 22px;
                }
    
                .year {
                    font-size: 20px;
                    color: rgb(104, 104, 104);
                }
    
                .activitiestitle {
                    font-size: 20px;
                    margin-top: 0.5%;
                }
    
                .activities {
                    font-size: 20px;
                    margin-left: 5%;
                }
            }
        }
    }

    .Skills {
        .container {
            display: flex;
            flex-direction: column;
            height: 100%;

            .title {
                font-size: 30px;
                font-weight: 400;
            }

            .content {
                .info {
                    margin: 0 10% 0 10%;
                    font-size: 20px;
                    text-align: center;
    
                    .section-title {
                        font-size: 25px;
                        font-weight: bold;
                    }
    
                    .section-text {
                        margin-bottom: 2%;
    
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .Awards {
        .container {
            display: flex;
            flex-direction: column;
            height: 100%;

            .title {
                font-size: 30px;
                font-weight: 400;
            }

            .content {
                .award {
                    margin: 0 6%;
                    font-size: 20px;
    
                    .award-info {
                        margin-bottom: 2%;
    
                        .name a {
                            font-size: 25px;
                        }
    
                        .issued {
                            color: rgb(104, 104, 104);
                        }
                    }

                    .award-desc {
                        line-height: 1.5;
                        margin-left: -1%;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .Experience {
        .container {
            display: flex;
            flex-direction: column;
            height: 100%;

            .title {
                font-size: 30px;
                font-weight: 400;
            }

            .content {
                display: flex;
                flex-direction: column;

                .workedat {
                    margin: 2% 6%;
                    font-size: 20px;

                    .total {
                        margin-bottom: 3%;

                        .date {
                            color: rgb(104, 104, 104);
                        }

                        .companyname {
                            font-size: 25px;
                        }
                    }

                    .jobdesc {
                        position: relative;
                        padding-left: 5%;

                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            height: 98.5%;
                            margin: auto 0;
                            border: 1px solid var(--main-bg-color);
                            border-radius: 10px;
                        }

                        .lead {
                            margin-bottom: 2.5%;
                        }

                        .pos {
                            margin-bottom: 2%;
                        }

                        .position {
                            font-size: 25px;
                            font-weight: bold;
                        }

                        .date {
                            color: rgb(104, 104, 104);
                        }

                        .text {
                            line-height: 1.5;
                            margin-left: -1%;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 1024px) {
    /* Profile card */
    .About .Profile .content {
        display: flex;
        flex-direction: column;
        text-align: center;

        .first {
            margin: 0;

            .company {
                margin-left: auto;
                margin-right: auto;
            }

            h3 {
                margin-bottom: 5%;
            }
        }

        .second {
            margin: auto;
            width: 80%;
            margin-bottom: 10%;
            letter-spacing: 2px;
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .About {
        .card {
            width: 90%;

            &:first-child {
                margin-top: 30px;
            }
            &:last-child {
                margin-bottom: 30px;
            }
        }
    }
}