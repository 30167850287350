.Projects {
    display: flex;
    flex-direction: column;

    .container {
        height: calc(100vh - 61px);
        align-content: center;
        overflow: hidden;

        .carousel-area {
            height: 350px;
            width: calc(100vw - 60px);
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            overflow-x: hidden;
            cursor: grab;

            #carousel {
                display: flex;
                flex-direction: row;
                position: relative;
                gap: 30px;

                .carousel-slide {
                    width: calc(50vw - 45px);
                    text-decoration: none;
                    box-sizing: border-box;
                    border: 1px solid var(--border-color);
                    border-radius: 12px;
                    overflow: hidden;

                    .slide {
                        height: 100%;
                        display: flex;
                        background-color: var(--container-bg-color);
                        text-decoration: none;

                        .info {
                            width: 70%;
                            color: black;
                            padding: 0 40px 0 25px;
                            user-select: none;
                            overflow-y: auto;
                            display: flex;
                            z-index: 1;
                            flex-direction: column;

                            @media only screen and (max-width: 1024px) {
                                width: 75%;
                            }

                            h4 {
                                font-size: 16px;
                                color: rgb(104, 104, 104);
                                font-weight: normal;
                                margin: 30px 0 5px 0;
                            }
                            
                            a {
                                width: fit-content;
                                
                                h1 {
                                    font-size: 25px;
                                    font-weight: bold;
                                    width: fit-content;
                                    margin: 0;
                                }
                            }

                            .flex-container {
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;

                                h2 {
                                    font-size: 18px;
                                    font-weight: normal;
                                    line-height: 1.4;
                                    margin: 30px 0 0 0;
                                }
    
                                h3 {
                                    font-size: 16px;
                                    color: rgb(104, 104, 104);
                                    font-weight: normal;
                                    word-spacing: 20px;
                                    margin-bottom: 30px;
                                }
                            }
                        }

                        .image {
                            width: 35%;
                            user-select: none;
                            
                            @media only screen and (max-width: 1024px) {
                                width: 30%;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }

        .slides {
            display: flex;
            width: fit-content;
            margin: 0 auto;
            gap: 30px;
            margin-top: 20px;

            .slide-circle {
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background-color: var(--container-bg-color);
                box-shadow: 0px 0px 0px 0.25px var(--container-boxshadow);

                &.visible {
                    background-color: rgb(0, 136, 255);
                }
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    .Projects {
        .container {
            height: 100vh;

            .carousel-area {
                #carousel {
                    .carousel-slide {
                        width: calc(100vw - 60px);
                    }
                }
            }
        }
    }
}