.Blog {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;

    .container {
        display: grid;
        grid-template-columns: 25% 50% 25%;
        width: 100%;
        height: calc(100% - 61px);

        #tabscolumn {
            background-color: var(--container-bg-color);
            display: flex;
            flex-direction: column;
            position: -webkit-sticky;
            position: sticky;
            top: 0;

            #tabs {
                .blog-post-button {
                    display: block;
                    font-size: 20px;
                    text-align: center;
                    cursor: pointer;
                    padding: 15px 20px;
                    border-radius: 15px;
                    -webkit-user-select: none; /* Safari */
                    -ms-user-select: none; /* IE 10 and IE 11 */
                    user-select: none; /* Standard syntax */
    
                    &:hover {
                        background-color: var(--main-bg-color);
                        color: var(--invert-color);
                    }
                }
            }
        }

        .outer-blog-container {
            background-color: var(--main-bg-color);
            color: var(--invert-color);
            width: 100%;
            margin: 50px 0;
            overflow-y: auto;

            .inner-blog-container {
                width: 90%;
                height: fit-content;
                margin: 0 auto;

                #title {
                    width: fit-content;
                    margin: 0 auto;
                    font-size: 30px;
                }

                #date {
                    width: fit-content;
                    margin: 0 auto 30px auto;
                    font-size: 12px;
                }

                #body {
                    margin: 0;
                    font-size: 20px;
                    white-space: pre-wrap;
                }
            }
        }

        .photos-container {
            overflow-y: auto;
            background-color: var(--container-bg-color);

            #photos {
                display: flex;
                flex-direction: column;
                row-gap: 5px;
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    .Blog {
        position: relative;

        .container {
            display: block;
            height: 100%;

            #tabscolumn {
                overflow: hidden;
                border-bottom: 1px solid var(--container-bg-color);

                #tabs {
                    .blog-post-button {
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 0;
                    }
                }
            }
        }

        .outer-blog-container {
            border-right: none;
            height: fit-content;
            width: 100%;
            position: unset;
        }
    }
}