.PageNotFound {
    height: 100%;
    width: 100%;
    position: fixed;

    .container {
        height: calc(100% - 61px);
        width: 100%;
        position: relative;

        .formcontainer {
            background-image: url("../../Images/Stow_Lake.webp");
            background-position: 0% 80%;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: calc(70vw);
            height: fit-content;
            border: 3px solid var(--invert-color);
            border-radius: 20px;
            box-shadow: 0px 0px 20px 10px var(--container-boxshadow);
        }

        .title {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: fit-content;
            width: fit-content;
            margin: auto auto;
            font-size: 100px;
            font-weight: bold;
            color: var(--invert-color);
            text-shadow: 0px 0px 20px white;
        }

        .description {
            position: relative;
            margin: 3% 5%;
            font-size: 25px;
            font-weight: 600;
            color: white;
            text-shadow: 0px 0px 5px black;
            text-align: center;
        }
    }
}


@media only screen and (max-width: 768px) {
    .PageNotFound .container {
        height: 100%;
    }
}