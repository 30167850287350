@font-face {
  font-family: 'Mona Sans';
  src:
    url('/src/Fonts/Mona\ Sans/Mona-Sans.woff2') format('woff2 supports variations'),
    url('/src/Fonts/Mona\ Sans/Mona-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Mona Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
}
button, input {
  font-family: 'Mona Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

li a {
  transition: 0.3s;
}
a {
  text-decoration: none;
}
a:link {
  color: #5D808F;
}
a:hover, a:active {
  color: #6CAFCC;
}

:root {
  --main-bg-color: #e9e9e9;
  --main-bg-color-alpha: rgba(233, 233, 233, 0.65);
  --container-bg-color: white;
  --container-bg-color-alpha: rgba(255, 255, 255, 0.95);
  --border-color: rgba(50, 50, 50, 0.05);
  --container-boxshadow: black;
  --section-underline: #e9e9e9;
  --invert-color: black;
}
[data-theme="light"] {
  --main-bg-color: #e9e9e9;
  --main-bg-color-alpha: rgba(233, 233, 233, 0.65);
  --container-bg-color: white;
  --container-bg-color-alpha: rgba(255, 255, 255, 0.95);
  --border-color: rgba(50, 50, 50, 0.05);
  --container-boxshadow: black;
  --section-underline: #e9e9e9;
  --invert-color: black;
}
[data-theme="dark"] {
  --main-bg-color: #323232;
  --main-bg-color-alpha: rgba(50, 50, 50, 0.65);
  --container-bg-color: #cfcfcf;
  --container-bg-color-alpha: rgba(207, 207, 207, 0.95);
  --border-color: rgba(233, 233, 233, 0.1);
  --container-boxshadow: white;
  --section-underline: #323232;
  --invert-color: white;
}
